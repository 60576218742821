import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
declare var $: any;
@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.css']
})
export class NavbarComponent implements OnInit {
  ancla: any;
  constructor(public router: Router) { }

  ngOnInit() {
    $(document).ready(function () {
      $('ul .scroll').click(function () {
        $(this).addClass('active').siblings().removeClass('active')
      })

      $('#navbarSupportedContent li').on('click', function () {
        $('#navbarSupportedContent li').find('li.active').removeClass('active');
        $(this).parent('li').addClass('active');
      });
    })
    $(window).scroll(function () {
      if ($('#navbar').offset().top > 70) {
        $('#navbar').addClass('bg-colnav');
      } else {
        $('#navbar').removeClass('bg-colnav');
      }
    });
  }
  ruta(item) {
   // console.log("entraaaaa");
    //$(document).ready(function() {
      //console.log("entraaaaa22222");
      //this.router.navigate(['principal']);
    //});
    //this.ancla = item;
    
    //window.location.hash= 'principal#' + this.ancla; 
    /* var URLactual = document.location.href;
  
      if (URLactual == 'http://192.168.0.11/canariasSoft/productos') {
        location.href = 'principal#' + this.ancla;*/
  }
}
  