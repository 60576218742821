import { Component, OnInit } from '@angular/core';
import { StorageService } from 'src/app/Servicios/storage.service';
declare var $: any;
@Component({
  selector: 'app-productos',
  templateUrl: './productos.component.html',
  styleUrls: ['./productos.component.css']
})
export class ProductosComponent implements OnInit {
  public imagenprod: any;
  gyp = false;
  za = false;
  constructor(public stse: StorageService) { }

  ngOnInit() {
    $(document).ready(function(){
      $(window).scrollTop(0);
  });
    this.imagenprod = this.stse.GetParamS("proy");
    if (this.imagenprod == '../../../assets/img/cmovil.png') {
      this.gyp = true
    } else if (this.imagenprod == '../../../assets/img/ZonasAzules.png') {
      this.za = true;
    }
  }

}
