import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import {OwlModule} from  'ngx-owl-carousel' ; 

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { PrincipalComponent } from './dashboard/principal/principal.component';
import { ProductosComponent } from './dashboard/productos/productos.component';
import { NavbarComponent } from './dashboard/navbar/navbar.component';
import { ServiciosComponent } from './dashboard/servicios/servicios.component';
import { ProyectosComponent } from './dashboard/proyectos/proyectos.component';
import { NosotrosComponent } from './dashboard/nosotros/nosotros.component';
import { ClientesComponent } from './dashboard/clientes/clientes.component';
import { ContactoComponent } from './dashboard/contacto/contacto.component';
import { MapaComponent } from './dashboard/mapa/mapa.component';
import { FooterComponent } from './dashboard/footer/footer.component';
import { CarruselComponent } from './dashboard/carrusel/carrusel.component';

@NgModule({
  declarations: [
    AppComponent,
    PrincipalComponent,
    ProductosComponent,
    NavbarComponent,
    ServiciosComponent,
    ProyectosComponent,
    NosotrosComponent,
    ClientesComponent,
    ContactoComponent,
    MapaComponent,
    FooterComponent,
    CarruselComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    OwlModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
