import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {ProductosComponent} from '../app/dashboard/productos/productos.component'
import {PrincipalComponent} from '../app/dashboard/principal/principal.component'
import { ServiciosComponent } from './dashboard/servicios/servicios.component';
import { ProyectosComponent } from './dashboard/proyectos/proyectos.component';
import { NosotrosComponent } from './dashboard/nosotros/nosotros.component';
import { ContactoComponent } from './dashboard/contacto/contacto.component';
import { ClientesComponent } from './dashboard/clientes/clientes.component';

const routes: Routes = [
  { path: 'principal', component: PrincipalComponent },
  { path: 'productos', component: ProductosComponent },
  { path: 'servicios', component: ServiciosComponent },
  { path: 'proyectos', component: ProyectosComponent },
  { path: 'nosotros', component: NosotrosComponent },
  { path: 'contacto', component: ContactoComponent },
  { path: 'clientes', component: ClientesComponent },
  { path: '', redirectTo: '/principal', pathMatch: 'full' },
  { path: '**', redirectTo: '/principal', pathMatch: 'full' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {useHash: true})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
