import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { StorageService } from 'src/app/Servicios/storage.service';
declare var $: any;
@Component({
  selector: 'app-proyectos',
  templateUrl: './proyectos.component.html',
  styleUrls: ['./proyectos.component.css']
})
export class ProyectosComponent implements OnInit {

  constructor(public router: Router
    , public sest: StorageService) { }

  ngOnInit() {
    /*Efecto desplazamiento del menu*/
    $(document).ready(function () {
      $('.scroll').click(function () {
        var destino = $(this.hash);
        if (destino.length == 0) {
          destino = $('a[name="' + this.hash.substr(1) + '"]');
        }
        if (destino.length == 0) {
          destino = $('html');
        }
        $('html, body').animate({ scrollTop: destino.offset().top }, 800);
        return false;
      });
    });
    //Filtro imagenes
    $(document).ready(function () {
       $('#portfolio-flters li').click(function () {
        var valor = $(this).attr('data-filter');
        if (valor == '*') {
          $('.portfolio-item ').show('1000');
        } else {
          $('.portfolio-item ').not('.' + valor).hide('1000');
          $('.portfolio-item ').filter('.' + valor).show('1000');
        }
        $('ul .filter').click(function () {
          $(this).addClass('activo').siblings().removeClass('activo')
        })

        $('#portfolio-flters li').on('click', function () {
          $('#portfolio-flters li').find('li.activo').removeClass('activo');
          $(this).parent('li').addClass('activo');
        });
      })
      $(".cambionav").click(function () {

        $("#navbar").addClass('bg-colnav2');
        $("#prueba").attr('id', 'prueba2');
        $(".icon").addClass('icon2');
        //$("#navbarSupported").addClass('navbarSupportedContent2');
        //$("#navbarSupported").attr('id', 'navbarSupportedContent2');
        $(".logo1").hide();
        $(".tamlog").show();
      });
    })
  }
  producto(proy) {
    this.sest.SetParamS('proy', proy);
    this.router.navigate(['productos']);
  }
  view(){
  }
}
