import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class StorageService {

  constructor() { }


   //Almacenamiento variables locales
   SetParamL(nom_param: string, param: string){
    localStorage.setItem(nom_param, param);
  }

  GetParamL(nom_param: string) : string{
    return localStorage.getItem(nom_param);
  }

  SetJSON(nom_param: string, param: any){
    localStorage.setItem(nom_param, JSON.stringify(param));
  }

  GetJSON(nom_param: string) : any{
    return JSON.parse(localStorage.getItem(nom_param));
  }

  //Almacenamiento variables por sesión
  SetParamS(nom_param: string, param: string){
    sessionStorage.setItem(nom_param, param);
  }

  GetParamS(nom_param: string) : string{
    return sessionStorage.getItem(nom_param);
  }
}
