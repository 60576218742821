import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { StorageService } from 'src/app/Servicios/storage.service';
declare var $: any;
@Component({
  selector: 'app-principal',
  templateUrl: './principal.component.html',
  styleUrls: ['./principal.component.css']
})
export class PrincipalComponent implements OnInit {
  productos: string;
  constructor(public router: Router
  , public sest: StorageService) { }

  ngOnInit() {
    /*Efecto desplazamiento del menu*/
    $(document).ready(function () {
      $('.scroll').click(function () {
        var destino = $(this.hash);
        if (destino.length == 0) {
          destino = $('a[name="' + this.hash.substr(1) + '"]');
        }
        if (destino.length == 0) {
          destino = $('html');
        }
        $('html, body').animate({ scrollTop: destino.offset().top }, 400);
        return false;
      });
    });
    //Filtro imagenes
    $(document).ready(function () {
      $('#portfolio-flters li').click(function () {
        var valor = $(this).attr('data-filter');
        if (valor == '*') {
          $('.portfolio-item ').show('1000');
        } else {
          $('.portfolio-item ').not('.' + valor).hide('1000');
          $('.portfolio-item ').filter('.' + valor).show('1000');
        }
        $('ul .filter').click(function () {
          $(this).addClass('active').siblings().removeClass('active')
        })
        
        $( '#portfolio-flters li' ).on( 'click', function () {
          $( '#portfolio-flters li' ).find( 'li.active' ).removeClass( 'active' );
          $( this ).parent( 'li' ).addClass( 'active' );
        });
      })
    })
  }
  producto(proy){
    this.sest.SetParamS('proy', proy);
    this.router.navigate(['productos']);
  }

}
